import { containsIgnoreCase, Str } from "core";
import {
    AdvancedTable,
    Icon,
    IconButton,
    PaginationBar,
    Paragraph,
    RowObject,
    Table,
    TableSortingRule,
    TextFilter,
    Tooltip,
    useBrandedMemo,
} from "design-system";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import * as Bates from "Everlaw/Bates";
import {
    getAllRankedFactoidsForAnswer,
    getFactoids,
    openDocInNewWindow,
    OracleFactoidObj,
    OracleInfo,
} from "Everlaw/Oracle/OracleUtils";
import * as React from "react";
import { ReactNode, useEffect, useId, useMemo, useRef, useState } from "react";

const ratingToString: Record<number, string> = {
    1: "Not relevant",
    2: "Barely relevant",
    3: "Somewhat relevant",
    4: "Directly relevant",
    5: "Highly relevant",
};

interface CitationRelevanceCellContentProps {
    relevanceScore: number;
}

function CitationRelevanceCellContent({
    relevanceScore,
}: CitationRelevanceCellContentProps): ReactNode {
    return (
        <div>
            <Paragraph>{relevanceScore}</Paragraph>
            <Paragraph className={"bb-text--small bb-text--color-secondary"}>
                {ratingToString[relevanceScore]}
            </Paragraph>
        </div>
    );
}

interface GeneratedFactCellProps {
    submissionId: number;
    quote: string;
    batesNumber: string;
    docId: number;
}

function GeneratedFactCell({
    submissionId,
    quote,
    batesNumber,
    docId,
}: GeneratedFactCellProps): ReactNode {
    const openDocButtonRef = useRef<HTMLButtonElement>(null);
    const quoteRef = useRef<HTMLParagraphElement>(null);
    const cellContentRef = useRef<HTMLDivElement>(null);
    const openDocButtonTooltipId = useId();
    const ellipsedQuoteTooltipId = useId();
    const [displayQuoteTooltip, setDisplayQuoteTooltip] = useState<boolean>(false);
    useEffect(() => {
        // Inspired by Table.tsx when ellipsifying cell contents. In this case, we don't ellipsify
        // the entire cell's contents - only the quote - so we have to redo some manual work here.
        if (quoteRef.current) {
            if (quoteRef.current.scrollHeight > quoteRef.current.clientHeight) {
                setDisplayQuoteTooltip(true);
            }
        }
    }, []);

    return (
        <>
            <div className={"flex-horizontal gap-8"} ref={cellContentRef}>
                <div className={"oracle-flex-vertical gap-4"}>
                    <Paragraph ref={quoteRef} className={"oracle-generated-fact-quote"}>
                        {quote}
                    </Paragraph>
                    <Paragraph className={"bb-text--small bb-text--color-secondary"}>
                        {batesNumber}
                    </Paragraph>
                </div>
                <div>
                    <IconButton
                        aria-label={"Open document in new window"}
                        onClick={() => openDocInNewWindow(submissionId, docId)}
                        ref={openDocButtonRef}
                    >
                        <Icon.Eye />
                    </IconButton>
                </div>
            </div>
            <Tooltip id={openDocButtonTooltipId} target={openDocButtonRef}>
                Open document
            </Tooltip>
            {displayQuoteTooltip && (
                <Tooltip id={ellipsedQuoteTooltipId} target={cellContentRef}>
                    {quote}
                </Tooltip>
            )}
        </>
    );
}

const PAGE_SIZE = 10;
const TABLE_FILTER_PLACEHOLDER = "Search in table"; // TODO: Double check with product
const RELEVANCE_COLUMN_TOOLTIP =
    "Each generated fact is given a relevance score from 1–5. Only"
    + " the most relevant facts are used in the response.";

interface AllRankedReferencesRow extends RowObject {
    id: number;
    referenceId: number | undefined;
    relevanceScore: React.ReactNode;
    generatedFact: React.ReactNode;
}

interface AllRankedReferencesProps {
    info: OracleInfo;
    citationToDisplayMap: Record<number, number>;
}

export function AllRankedReferences({
    info,
    citationToDisplayMap,
}: AllRankedReferencesProps): ReactNode {
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));
    const factIdToObj = useMemo(
        () => new Map(factRefs.map((ref) => [ref.obj.id, ref.obj])),
        [factRefs],
    );

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.allFactoids === undefined) {
            getAllRankedFactoidsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    const [sort, setSort] = useState<TableSortingRule<AllRankedReferencesRow> | undefined>({
        id: "relevanceScore",
        desc: true,
    });

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();

    const TABLE_HEADERS = useBrandedMemo(
        () => [
            {
                id: "referenceId",
                title: "Reference",
                width: 128,
                isSortable: true,
                ellipseCellContent: true,
            },
            {
                id: "relevanceScore",
                title: "Relevance",
                width: 152,
                isSortable: true,
                infoIcon: (
                    // TODO: update when copy is done
                    <Table.HeaderInfoIcon tooltipContent={RELEVANCE_COLUMN_TOOLTIP} />
                ),
                ellipseCellContent: true,
            },
            {
                id: "generatedFact",
                title: "Generated fact",
                isSortable: true,
            },
        ],
        [],
    );

    const allTableRows: AllRankedReferencesRow[] = useMemo(
        () =>
            (info.allFactoids ?? [])
                .map((f) => {
                    const factObj = factIdToObj.get(f.factId);
                    return factObj
                        ? {
                              id: f.factId,
                              referenceId: factObj.referenceId
                                  ? citationToDisplayMap[factObj.referenceId]
                                  : undefined,
                              rawRelevanceScore: factObj.relevanceScore,
                              quote: factObj.quote,
                              rawBatesDisplay: f.batesDisplay,
                              docId: f.docId,
                          }
                        : undefined;
                })
                .filter((row) => row !== undefined)
                .filter((row) => (filterValue ? containsIgnoreCase(row.quote, filterValue) : true))
                .sort((a, b) => {
                    const sortId = sort === undefined ? "relevanceScore" : sort.id;
                    let compare: number;
                    switch (sortId) {
                        case "referenceId":
                            // Sorting here works as follows:
                            // 1. If both referenceIds are defined, sort in referenceId order
                            // 2. Place rows with defined referenceIds above rows with undefined ids
                            // 3. Arbitrary sorting within rows with undefined referenceIds
                            if (a.referenceId !== undefined && b.referenceId !== undefined) {
                                compare = a.referenceId - b.referenceId;
                            } else if (a.referenceId === undefined && b.referenceId !== undefined) {
                                compare = 1;
                            } else if (a.referenceId !== undefined && b.referenceId === undefined) {
                                compare = -1;
                            } else {
                                compare = 0;
                            }
                            break;
                        case "relevanceScore":
                            compare = a.rawRelevanceScore - b.rawRelevanceScore;
                            break;
                        case "generatedFact":
                            compare = Bates.compare(
                                Bates.parseBates(a.rawBatesDisplay),
                                Bates.parseBates(b.rawBatesDisplay),
                            );
                            break;
                        default:
                            throw new Error("Invalid column name");
                    }
                    if (sort === undefined || sort.desc) {
                        compare = -compare;
                    }
                    return compare;
                })
                .map((row) => ({
                    id: row.id,
                    referenceId: row.referenceId,
                    relevanceScore: (
                        <CitationRelevanceCellContent relevanceScore={row.rawRelevanceScore} />
                    ),
                    generatedFact: (
                        <GeneratedFactCell
                            submissionId={info.id as number}
                            quote={row.quote}
                            batesNumber={row.rawBatesDisplay}
                            docId={row.docId}
                        />
                    ),
                })),
        [info.id, info.allFactoids, filterValue, sort, factIdToObj, citationToDisplayMap],
    );

    const [currentPage, setCurrentPage] = useState(1);

    const rows = useBrandedMemo<AllRankedReferencesRow[]>(() => {
        const start = (currentPage - 1) * PAGE_SIZE;
        return allTableRows.slice(start, start + PAGE_SIZE);
    }, [currentPage, allTableRows]);

    return (
        <>
            <div className={"oracle-table-action-bar"}>
                <div className={"flex-centered"}>
                    {Str.countOf(info.allFactoids?.length ?? 0, "generated fact")}
                </div>
                <Table.ActionBar
                    textFilter={
                        <TextFilter
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            setFilterValue={setFilterValue}
                            showInput={showInput}
                            setShowInput={setShowInput}
                            aria-label={TABLE_FILTER_PLACEHOLDER}
                            placeholder={TABLE_FILTER_PLACEHOLDER}
                        />
                    }
                />
            </div>
            <AdvancedTable
                className={"oracle-references-table"}
                aria-label={"All references ranked by LLM for the answer"}
                headers={TABLE_HEADERS}
                objects={rows}
                sortManually={true}
                currentSort={sort}
                setCurrentSort={(newSort?: TableSortingRule<AllRankedReferencesRow>) => {
                    setSort(newSort);
                }}
                placeholder={"No references to display"}
                getKey={(obj) => obj.id}
                showGridLines={true}
                minWidth={500}
                maxWidth={800}
                // 2px of height are automatically added for borders, making the total height 600
                maxHeight={598}
                paginationBar={
                    <PaginationBar
                        numTotalRows={allTableRows.length}
                        pageSize={PAGE_SIZE}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    );
}

interface ReferencesUsedInAnswerRow extends RowObject {
    id: number;
    referenceId: number;
    relevanceScore: ReactNode;
    generatedFact: ReactNode;
}

interface ReferencesUsedInAnswerProps {
    info: OracleInfo;
    citationToDisplayMap: Record<number, number>;
}

export function ReferencesUsedInAnswer({
    info,
    citationToDisplayMap,
}: ReferencesUsedInAnswerProps): ReactNode {
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));
    const factIdToObj = useMemo(
        () => new Map(factRefs.map((ref) => [ref.obj.id, ref.obj])),
        [factRefs],
    );

    const fIds = useMemo(
        () => new Set(info.answerCitations.map((f) => f.factId)),
        [info.answerCitations],
    );

    useEffect(() => {
        if (!fIds) {
            return;
        }
        const fIdsToFetch = [...fIds].filter((id) => Base.get(OracleFactoidObj, id) === undefined);
        if (fIdsToFetch.length > 0) {
            getFactoids(fIdsToFetch, info.questionSubmission.submissionId);
        }
    }, [fIds, info.questionSubmission.submissionId]);

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();

    const [sort, setSort] = useState<TableSortingRule<ReferencesUsedInAnswerRow> | undefined>({
        id: "referenceId",
        desc: false,
    });

    const TABLE_HEADERS = useBrandedMemo(
        () => [
            {
                id: "referenceId",
                title: "Reference",
                width: 128,
                isSortable: true,
                ellipseCellContent: true,
            },
            {
                id: "relevanceScore",
                title: "Relevance",
                width: 152,
                isSortable: true,
                infoIcon: (
                    // TODO: update when copy is done
                    <Table.HeaderInfoIcon tooltipContent={RELEVANCE_COLUMN_TOOLTIP} />
                ),
                ellipseCellContent: true,
            },
            {
                id: "generatedFact",
                title: "Generated fact",
                isSortable: true,
            },
        ],
        [],
    );

    const allTableRows: ReferencesUsedInAnswerRow[] = useMemo(
        () =>
            (info.answerCitations ?? [])
                .map((f) => {
                    const factObj = factIdToObj.get(f.factId);
                    return factObj
                        ? {
                              id: f.factId,
                              referenceId: citationToDisplayMap[f.referenceId],
                              rawRelevanceScore: factObj?.relevanceScore,
                              quote: factObj.quote,
                              rawBatesDisplay: f.batesDisplay,
                              docId: f.docId,
                          }
                        : undefined;
                })
                .filter((row) => row !== undefined)
                .filter((row) => (filterValue ? containsIgnoreCase(row.quote, filterValue) : true))
                .sort((a, b) => {
                    const sortId = sort === undefined ? "referenceId" : sort.id;
                    let compare: number;
                    switch (sortId) {
                        case "referenceId":
                            compare = a.referenceId - b.referenceId;
                            break;
                        case "relevanceScore":
                            compare = a.rawRelevanceScore - b.rawRelevanceScore;
                            break;
                        case "generatedFact":
                            compare = Bates.compare(
                                Bates.parseBates(a.rawBatesDisplay),
                                Bates.parseBates(b.rawBatesDisplay),
                            );
                            break;
                        default:
                            throw new Error("Invalid column name");
                    }
                    if (sort?.desc) {
                        compare = -compare;
                    }
                    return compare;
                })
                .map((row) => ({
                    id: row.id,
                    referenceId: row.referenceId,
                    relevanceScore: (
                        <CitationRelevanceCellContent relevanceScore={row.rawRelevanceScore} />
                    ),
                    generatedFact: (
                        <GeneratedFactCell
                            submissionId={info.id as number}
                            quote={row.quote}
                            batesNumber={row.rawBatesDisplay}
                            docId={row.docId}
                        />
                    ),
                })),
        [info, factIdToObj, filterValue, sort, citationToDisplayMap],
    );

    const [currentPage, setCurrentPage] = useState(1);

    const rows = useBrandedMemo<ReferencesUsedInAnswerRow[]>(() => {
        const start = (currentPage - 1) * PAGE_SIZE;
        return allTableRows.slice(start, start + PAGE_SIZE);
    }, [currentPage, allTableRows]);

    return (
        <>
            <div className={"oracle-table-action-bar"}>
                <div className={"flex-centered"}>
                    {Str.countOf(info.answerCitations.length, "generated fact")}
                </div>
                <Table.ActionBar
                    textFilter={
                        <TextFilter
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            setFilterValue={setFilterValue}
                            showInput={showInput}
                            setShowInput={setShowInput}
                            aria-label={TABLE_FILTER_PLACEHOLDER}
                            placeholder={TABLE_FILTER_PLACEHOLDER}
                        />
                    }
                />
            </div>
            <AdvancedTable
                className={"oracle-references-table"}
                aria-label={"Highly relevant references used by LLM for the answer"}
                headers={TABLE_HEADERS}
                sortManually={true}
                currentSort={sort}
                setCurrentSort={(newSort?: TableSortingRule<ReferencesUsedInAnswerRow>) => {
                    setSort(newSort);
                }}
                objects={rows}
                placeholder={"No references to display"}
                getKey={(obj) => obj.id}
                showGridLines={true}
                minWidth={500}
                maxWidth={800}
                // 2px of height are automatically added for borders, making the total height 600
                maxHeight={598}
                paginationBar={
                    <PaginationBar
                        numTotalRows={allTableRows.length}
                        pageSize={PAGE_SIZE}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    );
}
